
export default {
  data() {
    return {
      visible: false
    }
  },
  computed: {
    phone() {
      return this.$contact.mobile[0] || this.$contact.phone[0]
    }
  },
  methods: {
    onClose() {
      this.visible = false
      localStorage.setItem('phone-alert', 'false')
    }
  },
  mounted() {
    this.visible = localStorage.getItem('phone-alert') != 'false'
  }
}
