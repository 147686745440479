import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=4f8bd0f6"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutInfoBar: require('/opt/build/repo/components/layout/InfoBar.vue').default,LayoutHomeHeader: require('/opt/build/repo/components/layout/HomeHeader.vue').default,LayoutMobileHeader: require('/opt/build/repo/components/layout/MobileHeader.vue').default,LayoutMenuBar: require('/opt/build/repo/components/layout/MenuBar.vue').default,LayoutFooter: require('/opt/build/repo/components/layout/Footer.vue').default,PhoneAlert: require('/opt/build/repo/components/PhoneAlert.vue').default,EshopCartDrawer: require('/opt/build/repo/node_modules/@evince/eshop/lib/packages/CartDrawer.vue').default,EshopCompareBar: require('/opt/build/repo/node_modules/@evince/eshop/lib/packages/CompareBar.vue').default,ScCookieBar: require('/opt/build/repo/node_modules/@evince/site/lib/packages/CookieBar.vue').default})
