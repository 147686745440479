
export default {
  vessel: {
    button: {
      width: 'sm',
      height: 'sm',
      fontSize: 'lg'
    }
  },
  computed: {
    phone() {
      return this.$contact.mobile[0] || this.$contact.phone[0]
    }
  }
}
