
export default {
  vessel: {
    wrapper: {
      backgroundColor: 'black',
      padding: 'xs',
      color: 'white'
    },
    infoBar: {
      fontSize: { all: 'xs', lg: 'sm' },
      fontWeight: 'light'
    }
  },
  async fetch() {
    const { data } = await this.$graphql.query({
      path: 'eshop/promotion',
      query: 'Banner',
      variables: { where: { type_in: ['shipping'] } }
    })

    this.promotion = data[0]
  },
  data() {
    return {
      promotion: null
    }
  },
  computed: {
    phone() {
      return this.$contact.mobile[0] || this.$contact.phone[0]
    },
    socialAccounts() {
      return [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/flip2store.gr/'
        },
        {
          name: 'Twitter',
          icon: 'twitter',
          url: 'https://twitter.com/flip2store'
        },
        {
          name: 'Instagram',
          icon: 'instagram',
          url: 'https://www.instagram.com/flip2store/'
        }
      ]
    }
  }
}
